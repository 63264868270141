import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { DashboardTab } from './tabs/DashboardTab';
import { textStyles } from '../../theme/TextClasses';
import { VariationsTab } from './tabs/VariationsTab';
import { ProfitsTab } from './tabs/ProfitsTab';
import { OrdersTab } from './tabs/OrdersTab';
import useArticlesDetail from '../../hooks/useArticlesDetail';
import { BundleTab } from './tabs/BundleTab';
import ActivityStream from '../activitystream/ActivityStream';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'relative',
    bottom: '-1px',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(4)
  },
  tabContainer: {
    backgroundColor: '#fafafa',
    padding: theme.spacing(3),
    paddingTop: 0
  },
  tabs: {
    height: 44,
    minHeight: 44,
    '& .MuiTabs-scrollableX ': {
      overflowX: 'auto !important'
    },
    '& .MuiTabs-indicator': {
      minHeight: 4,
      height: 4,
      bottom: -4,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: 0,
      '&::after': {
        position: 'absolute',
        left: '50%',
        width: 28,
        content: '" "',
        marginLeft: -14,
        borderRadius: 'inherit',
        height: '100%'
      }
    }
  },
  tab: {
    '&.MuiButtonBase-root': {
      height: 44,
      minHeight: 44,
      backgroundColor: '#eceeef',
      border: `1px solid ${theme.palette.divider}`,
      position: 'relative',
      borderRadius: '5px 5px 0px 0px',
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      '&::after': {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: 1,
        content: '" "',
        borderRadius: 'inherit'
      },
      '.MuiTouchRipple-root': {
        opacity: 0.1
      },
      '&:hover': {
        color: theme.palette.grey[800]
      },
      '&.Mui-selected': {
        zIndex: 1,
        color: theme.palette.grey[800],
        backgroundColor: '#fafafa',
        borderBottom: `1px solid #fafafa`,
        height: 46,
        '&::after': {
          height: 0
        }
      }
    }
  },
  tabTitle: {
    margin: theme.spacing(2, 0),
    color: theme.palette.text.primary
  }
}));

type DetailTabsProps = {
  tabsRef: React.Ref<HTMLDivElement>;
  tabSelected: string;
  onSelectTab: (selected: string) => void;
  onSelectProduct(articleId: number): void;
};

export function DetailTabs({
  tabsRef,
  tabSelected,
  onSelectTab,
  onSelectProduct
}: DetailTabsProps) {
  const { t: translate } = useTranslation(['component']);
  const classes = useStyles();
  const textClasses = textStyles({});
  const theme: Theme = useTheme();

  const {
    article: { name, ean, isBundle }
  } = useArticlesDetail();

  return (
    <TabContext value={tabSelected}>
      <Box className={classes.container} component="div" ref={tabsRef}>
        <Tabs
          className={classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
          value={tabSelected}
          onChange={(e, v) => onSelectTab(v)}
          aria-label="Product detail tabs"
        >
          <Tab
            className={classes.tab}
            label={translate('dashboard')}
            value="0"
          />
          <Tab className={classes.tab} label={translate('orders')} value="1" />
          <Tab
            className={classes.tab}
            label={translate('menu:profit_report')}
            value="2"
          />
          <Tab
            className={classes.tab}
            label={translate('variations')}
            value="3"
          />
          <Tab
            className={classes.tab}
            label={translate('bundle')}
            value="4"
            disabled={isBundle === false}
          />
          <Tab
            className={classes.tab}
            label={translate('activity_stream')}
            // disabled
            value="5"
          />
          <Tab
            className={classes.tab}
            label={translate('suggestions')}
            disabled
            value="6"
          />
          <Tab
            className={classes.tab}
            label={translate('audience')}
            disabled
            value="7"
          />
          <Tab
            className={classes.tab}
            label={translate('component:returns')}
            disabled
            value="8"
          />
          <Tab
            className={classes.tab}
            label={translate('component:online_advertising')}
            disabled
            value="9"
          />
        </Tabs>
      </Box>
      <Paper className={classes.tabContainer} variant="outlined">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="h5"
              className={`${textClasses.ellipsis} ${classes.tabTitle}`}
            >
              {`${name} ${ean}`}
            </Typography>
          </Grid>
          {tabSelected === '3' && (
            <Grid item>
              <Typography variant="h6" color={theme.palette.primary.main}>
                {translate('component:last_num_days', { days: 30 })}
              </Typography>
            </Grid>
          )}
        </Grid>

        <TabPanel value="0">
          <DashboardTab />
        </TabPanel>
        <TabPanel value="1">
          <OrdersTab />
        </TabPanel>
        <TabPanel value="2">
          <ProfitsTab />
        </TabPanel>
        <TabPanel value="3">
          <VariationsTab onSelectProduct={onSelectProduct} />
        </TabPanel>
        <TabPanel value="4">
          <BundleTab />
        </TabPanel>
        <TabPanel value="5">
          <ActivityStream />
        </TabPanel>
        <TabPanel value="6">
          <Paper variant="outlined" sx={{ padding: 2 }}>
            Suggestions
          </Paper>
        </TabPanel>
        <TabPanel value="7">
          <Paper variant="outlined" sx={{ padding: 2 }}>
            Listing
          </Paper>
        </TabPanel>
        <TabPanel value="8">
          <Paper variant="outlined" sx={{ padding: 2 }}>
            {translate('component:returns')}
          </Paper>
        </TabPanel>
        <TabPanel value="9">
          <Paper variant="outlined" sx={{ padding: 2 }}>
            {translate('component:online_advertising')}
          </Paper>
        </TabPanel>
      </Paper>
    </TabContext>
  );
}
