import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { Icon } from '@iconify/react';
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  half: {
    flex: 1
  },

  status: {
    borderRadius: 7,
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: ({ color }) => color
  },
  space: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icontainer: {
    paddingTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.75),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

type PriceCellProps = {
  price: number;
  pricePercentage?: number;
  isCurrency?: boolean;
  // showPercentage?: boolean;
  showIcon?: boolean;
};

export function PriceCell({
  price,
  pricePercentage,
  isCurrency = true,
  showIcon = true
}: PriceCellProps) {
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const iconProps = useMemo(() => {
    if (pricePercentage) {
      if (pricePercentage === 0) {
        return {
          color: '#FFA319',
          icon: 'lucide:equal'
        };
      }
      if (pricePercentage < 0) {
        return {
          color: '#FF1943',
          icon: 'akar-icons:chevron-down'
        };
      }
      return {
        color: '#57CA22',
        icon: 'akar-icons:chevron-up'
      };
    }
    return {
      color: '#FFA319',
      icon: 'lucide:equal'
    };
  }, [pricePercentage]);

  const classes = useStyles({ color: iconProps.color });

  /*  useEffect(() => {
    if (pricePercentage) {
      if (pricePercentage === 0) {
        setColor('#FFA319');
        setIcon('lucide:equal');
      } else if (pricePercentage < 0) {
        setColor('#FF1943');
        // setIcon('ant-design:caret-down-filled');
        setIcon('akar-icons:chevron-down');
      } else {
        setColor('#57CA22');
        // setIcon('ant-design:caret-up-filled');
        setIcon('akar-icons:chevron-up');
      }
    }
  }, [pricePercentage]); */

  return (
    <Box className={classes.root}>
      <Box className={classes.half}>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {isCurrency
            ? `${new Intl.NumberFormat(currentLang.value, {
                style: 'currency',
                currency: iso
              }).format(price || 0)}`
            : price}
        </Typography>
      </Box>
      <Box className={`${classes.half} ${classes.space}`}>
        {pricePercentage && (
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, color: iconProps.color }}
          >
            {new Intl.NumberFormat(currentLang.value, {
              style: 'percent',
              maximumFractionDigits: 2
            }).format(pricePercentage / 100)}
          </Typography>
        )}
        {pricePercentage && showIcon && (
          <Box className={classes.icontainer}>
            <Icon
              width={16}
              height={16}
              icon={iconProps.icon}
              color={iconProps.color}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

/* <Box className={classes.root}>
    <Typography variant="body1" sx={{ fontWeight: 700 }}>
      { isCurrency ? `${new Intl.NumberFormat(currentLang.value, {
        style: 'currency',
        currency: 'EUR'
      }).format(price)}` : price }
    </Typography>
    <Box className={classes.statusSpace}>
      {showPercentage && <Box className={classes.status}>
        <Typography variant="caption" sx={{ fontWeight: 300 }}>
          {new Intl.NumberFormat(currentLang.value, {
            style: 'percent',
            maximumFractionDigits: 2
          }).format(pricePercentage / 100)}
        </Typography>
      </Box>}
    </Box>
  </Box> */
