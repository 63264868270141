import {
  createSlice,
  PayloadAction,
  ThunkDispatch,
  AnyAction
} from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { format, subDays } from 'date-fns';
import axios from '../../utils/axios';
import { sortByProperty } from '../../utils/sortValues';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { ArticleVariantsState, Pagination } from '../../@types/reduxStates';
import { ProductListRow } from '../../@types/tableRows';
import { ProductListResponse } from '../../@types/responsesAPI';
import { DateRangeFilter, SortBy } from '../../@types/filters';

const initialState: ArticleVariantsState = {
  filters: {
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    }
  },
  response: {
    rows: [],
    total: {
      revenue: 0,
      orders: 0,
      margin: 0,
      stock: 0,
      avgPrice: 0,
      returns: 0,
      activityCritical: 0,
      activityNormal: 0,
      productHealth: 0
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'articleVariants',
  initialState,
  reducers: {
    setArticleVariants: (
      state: any,
      action: PayloadAction<Array<ProductListRow>>
    ) => {
      state.items = action.payload;
    },
    setFilters: (
      state: any,
      action: PayloadAction<{ timePeriod: DateRangeFilter }>
    ) => {
      state.filters = action.payload;
    },
    getArticleVariants: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getArticleVariantsSuccess: (
      state: any,
      action: PayloadAction<ProductListResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = action.payload;
    },
    getArticleVariantsError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export async function fetchArticleVariantsData(
  filters: { timePeriod: DateRangeFilter },
  pagination: Pagination,
  sort: SortBy,
  signal: AbortSignal,
  variant: number
): Promise<any> {
  const { timePeriod } = filters;
  const { skip, limit } = pagination;
  const { by, order } = sort;

  const response = await axios.get('/api/v2/articleVariants', {
    signal,
    params: {
      articleExtId: variant,
      skip,
      limit,
      by,
      order,
      from: timePeriod.startFilter,
      till: timePeriod.endFilter
    }
  });

  return response.data;
}

export function fetchArticleVariants(
  filters: { timePeriod: DateRangeFilter },
  pagination: Pagination,
  sort: SortBy,
  abortController: AbortController,
  variant: number
) {
  return async (dispatch: any) => {
    dispatch(getArticleVariants());
    dispatch(setFilters(filters));
    try {
      const data = await fetchArticleVariantsData(
        filters,
        pagination,
        sort,
        abortController.signal,
        variant
      );
      dispatch(getArticleVariantsSuccess(data));
    } catch (error) {
      dispatch(getArticleVariantsError(error as APIError));
    }
  };
}

export function sortBy(
  list: Array<ProductListRow>,
  orderBy: string,
  order: 'asc' | 'desc'
) {
  const orderedList = sortByProperty(list, orderBy, order);
  return (
    dispatch: ThunkDispatch<any, null, AnyAction> &
      ThunkDispatch<any, undefined, AnyAction> &
      Dispatch<any>
  ) => dispatch(setArticleVariants(orderedList));
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setArticleVariants,
  setFilters,
  getArticleVariants,
  getArticleVariantsSuccess,
  getArticleVariantsError
} = slice.actions;
