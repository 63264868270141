import { Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled: boolean;
  range: { from: number; to: number };
  onChangeRange: (range: { from: number; to: number }) => void;
  onError: (error: boolean) => void;
};

const RowsRangeCSV = ({ disabled, range, onChangeRange, onError }: Props) => {
  const { t: translate } = useTranslation(['component', 'filter']);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (/^\d*$/.test(value)) {
      const parsedValue = parseInt(value, 10);

      const newRange = { ...range, [name]: parsedValue || 0 };

      if (
        newRange.from > newRange.to ||
        newRange.from <= 0 ||
        newRange.to <= 0
      ) {
        onError(true);
      } else {
        onError(false);
      }

      onChangeRange(newRange);
    }
  };

  return (
    <Box display="flex" width="sx" margin="auto" mt={2}>
      <TextField
        disabled={disabled}
        size="small"
        label={translate('filter:filter_from_row')}
        variant="outlined"
        margin="normal"
        name="from"
        value={range.from}
        onChange={handleChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
      <Box m={4} />
      <TextField
        disabled={disabled}
        size="small"
        label={translate('filter:filter_to_row')}
        variant="outlined"
        margin="normal"
        name="to"
        value={range.to}
        onChange={handleChange}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
    </Box>
  );
};

export default RowsRangeCSV;
