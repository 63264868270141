import {
  Box,
  Typography,
  Grid,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import { useMemo, useState } from 'react';
import useLocales from '../../hooks/useLocales';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { TendingIcon } from './TendingIcon';
import { CardLoading } from './CardLoading';
import { CardError } from './CardError';
import { CardNoResult } from './CardNoResult';
import { ByChannelReponse } from '../../@types/responsesAPI';
import PieChart from '../charts/PieChart';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    width: '100%',
    padding: theme.spacing(2)
  },
  subHeader: {
    width: '100%',
    padding: theme.spacing(0, 2, 2, 0)
  },
  footer: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  footerLeft: {
    padding: theme.spacing(2),
    // paddingTop: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.divider}`
  },
  footerRight: {
    padding: theme.spacing(2)
    // paddingTop: theme.spacing(1)
  },
  content: {
    position: 'relative',
    // height: ({ size }) => size,
    width: '100%'
    // padding: theme.spacing(0, 2)
  },
  revEuros: {
    // paddingLeft: theme.spacing(1)
  }
}));

export type FooterData = {
  name: string;
  left: {
    value: string;
    percent: number;
  };
  right: {
    value: string;
    percent: number;
  };
};

type Props = {
  data: ByChannelReponse;
  state: APIStatus;
  error?: APIError;
  title?: string;
};

export default function PolarAreaComponent({
  data,
  state,
  error,
  title = ''
}: Props) {
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const theme: Theme = useTheme();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  const currencyF = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso
  });

  const percentF = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2,
    // @ts-ignore
    signDisplay: 'exceptZero'
  });

  const [footer, setFooter] = useState<FooterData>({
    name: '',
    left: {
      value: currencyF.format(0),
      percent: 0
    },
    right: {
      value: currencyF.format(0),
      percent: 0
    }
  });

  const chartData = useMemo(() => {
    const cD: ByChannelReponse = [];
    const others = {
      name: translate('others'),
      margin: 0,
      marginBefore: 0,
      marginTending: 0,
      marginPercent: 0,
      revenue: 0,
      revenueBefore: 0,
      revenuePercent: 0,
      revenueTending: 0
    };
    data.length > 0 &&
      data.forEach((c, index: number) => {
        if (index < 6) {
          cD.push(c);
        } else {
          others.revenue += c.revenue;
          others.revenuePercent += c.revenuePercent;
          others.margin += c.margin;
          others.marginBefore += c.marginBefore;
          others.marginTending += c.marginTending;
          others.marginPercent += c.marginPercent;
          others.revenue += c.revenue;
          others.revenueBefore += c.revenueBefore;
          others.revenueTending += c.revenueTending;
        }
      });
    if (others.revenue > 0) cD.push(others);
    updateIndex(-1, cD);
    return cD;
  }, [data]);

  function updateIndex(index: number, cData: ByChannelReponse) {
    let fData: FooterData = {
      name: '',
      left: {
        value: currencyF.format(0),
        percent: 0
      },
      right: {
        value: currencyF.format(0),
        percent: 0
      }
    };
    if (data.length > 0) {
      if (index === -1) {
        fData = {
          name: '',
          left: {
            value: currencyF.format(
              data.map((c) => c.revenue).reduce((prev, next) => prev + next)
            ),
            percent:
              data
                .map((c) => c.revenueTending)
                .reduce((prev, next) => prev + next) / data.length
          },
          right: {
            value: currencyF.format(
              data.map((c) => c.margin).reduce((prev, next) => prev + next)
            ),
            percent:
              data
                .map((c) => c.revenueTending)
                .reduce((prev, next) => prev + next) / data.length
          }
        };
      } else {
        fData = {
          name: cData[index].name,
          left: {
            value: currencyF.format(cData[index].revenue),
            percent: cData[index].revenueTending
          },
          right: {
            value: currencyF.format(cData[index].margin),
            percent: cData[index].marginTending
          }
        };
      }
    }
    setFooter(fData);
  }

  return (
    <Grid container direction="column">
      <Grid item xs={12} sx={{ position: 'relative', minHeight: '420px' }}>
        {state === APIStatus.PENDING && <CardLoading />}
        {error && <CardError />}
        {state === APIStatus.FULFILLED && chartData.length === 0 && !error && (
          <CardNoResult />
        )}
        {state === APIStatus.FULFILLED && chartData.length > 0 && (
          <PieChart
            data={chartData}
            onSourceSelect={(index) => updateIndex(index, chartData)}
          />
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '36px' }}
      >
        <Typography
          // my={1}
          variant="h6"
          fontWeight={700}
          // className={textClasses.ellipsisFront}
          // sx={{ display: 'inline-block' }}
        >
          {footer.name}
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          minHeight: '100px',
          borderRadius: 1
        }}
      >
        <Grid
          px={2}
          py={1}
          container
          item
          xs={12}
          sm={6}
          sx={{ borderRight: `1px solid ${theme.palette.divider}` }}
        >
          <Grid container item>
            <Typography variant="h6" fontWeight={600}>
              {`${translate('order_rev')}`}
            </Typography>
          </Grid>
          <Grid container item justifyContent="space-between">
            <Typography variant="h6" sx={{ fontWeight: 300 }}>
              {`${footer.left.value}(${percentF.format(
                footer.left.percent / 100
              )})`}
            </Typography>
            <Box>
              <TendingIcon tending={footer.left.percent} />
            </Box>
          </Grid>
        </Grid>
        <Grid px={2} py={1} container item xs={12} sm={6}>
          <Grid container item>
            <Typography variant="h6" fontWeight={600}>
              {`${translate('margin')}`}
            </Typography>
          </Grid>
          <Grid container item justifyContent="space-between">
            <Typography variant="h6" sx={{ fontWeight: 300 }}>
              {`${footer.right.value}(${percentF.format(
                footer.right.percent / 100
              )})`}
            </Typography>
            <Box>
              <TendingIcon tending={footer.right.percent} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
