import { Grid } from '@material-ui/core';
import useArticlesDetail from '../../../hooks/useArticlesDetail';
import Variations from '../../variations/Variations';

type VariantsProps = {
  onSelectProduct: (articleId: number) => void;
};

export function VariationsTab({ onSelectProduct }: VariantsProps) {
  const {
    article: { articleExtId }
  } = useArticlesDetail();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Variations
          onSelectProduct={onSelectProduct}
          articleVariant={articleExtId}
        />
      </Grid>
    </Grid>
  );
}
