import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { APIStatus } from '../../@types/APIStatus';
import { TableStyleClasses } from '../../theme/table/TableClasses';
import { ProductListRow } from '../../@types/tableRows';
import ActiveMarketsCell from '../items/cells/ActiveMarketsCell';
import { HealthCircleCell } from '../items/cells/HealthCircleCell';
import { TendingCell } from '../items/cells/TendingCell';
import { TextCell } from '../items/cells/TextCell';
import { TotalTodayCell } from '../items/cells/TotalToday';
import { ProductsTableProps } from '../items/ProductsTable';
import { NavigateToParams } from '../../@types/menu';
import useVariants from '../../hooks/useVariants';
import SummaryHeaderCell from '../tables/SummaryHeaderCell';
import { useConstants } from '../../ConstantsContext';
import ArticleLinkInfo from '../items/cells/ArticleLinkInfo/ArticleLinkInfo';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import { limitMinMax, unknownToNumber } from '../../utils/helper';
import useActivityStream from '../../hooks/useActivityStream';
import CardTableStatus from '../general/CardTableStatus';
import TableHorizontalScroll from '../general/TableHorizontalScroll';
import { CurrentStockCell } from '../tables/cells/CurrentStockCell';
import { PriceCell } from '../items/cells/PriceCell';

export default function VariationsTable({
  sortBy,
  onSortChange,
  onSelectProduct
}: ProductsTableProps) {
  const history = useHistory();
  const { currentLang } = useLocales();
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';
  const { HEIGHT_PRODUCT_CELL } = useConstants();
  const [numRowsResult, setNumRowsResult] = useState<number>(3);
  const {
    setFilters: setActivityStreamFilters,
    filters: activityStreamFilters
  } = useActivityStream();

  const targetOberverRef = useRef<HTMLTableRowElement | null>(null);

  const {
    filters: {
      timePeriod: { startFilter, endFilter }
    },
    summary,
    rows,
    APIStatus: state,
    error
  } = useVariants();

  useEffect(() => setNumRowsResult(rows.length < 3 ? 3 : rows.length), [rows]);

  const tableClasses = TableStyleClasses({
    height: HEIGHT_PRODUCT_CELL,
    numRows: numRowsResult
  });

  const navigateToDetails = ({
    idArticle,
    buttonPressed = 3,
    tab = 0
  }: NavigateToParams) => {
    const pageId = history.location.pathname.split('/');
    if (pageId[3] !== idArticle) {
      history.replace(`/product/${idArticle}/${tab}`);
      onSelectProduct && onSelectProduct(Number(idArticle));
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const euro = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    maximumFractionDigits: 2,
    currency: iso
  });

  function getCell(field: string, row: ProductListRow): React.ReactNode {
    switch (field) {
      case 'product': {
        return (
          <ArticleLinkInfo
            name={row.name}
            imageSmall={row.image}
            ean={row.ean}
            asin={row.asin}
            sku={row.articleNumber}
            parentId={parseInt(row.articleExtId, 10)}
            variants={row.variants - 1}
            attributes={row.attributes ?? []}
            onGoToDetail={(buttonPressed, tab) =>
              navigateToDetails({
                idArticle: row.articleId,
                buttonPressed,
                tab
              })
            }
          />
        );
      }
      case 'stock': {
        return <CurrentStockCell stock={row.stock} />;
      }
      case 'avgPrice': {
        return <PriceCell price={row.avgPrice} />;
      }
      case 'revenue':
      case 'margin': {
        const value = row[field as keyof ProductListRow];
        if (typeof value === 'number') {
          const amount = euro.format(value);
          return <TextCell text={amount} />;
        }
        return <TextCell text="-" />;
      }
      case 'orders':
      case 'returns': {
        return <TextCell text={`${row[field as keyof ProductListRow]}`} />;
      }
      case 'activity': {
        return (
          <TotalTodayCell
            total={row.activityNormal ?? 0}
            today={row.activityCritical ?? 0}
            warning={true}
            onNavigateTo={(btn) => {
              setActivityStreamFilters({
                ...activityStreamFilters,
                timePeriod: { startFilter, endFilter }
              });
              navigateToDetails({
                idArticle: row.articleId,
                buttonPressed: btn,
                tab: 5
              });
            }}
          />
        );
      }
      case 'recommendations': {
        return (
          <TotalTodayCell
            total={row.recommendationsTotal ?? 0}
            today={row.recommendationsToday ?? 0}
            warning={true}
            onNavigateTo={(btn) =>
              navigateToDetails({
                idArticle: row.articleId,
                buttonPressed: btn,
                tab: 6
              })
            }
          />
        );
      }
      case 'activeMarketsData': {
        return (
          <ActiveMarketsCell
            markets={row.activeMarketsData}
            onGoToDetail={() =>
              navigateToDetails({
                idArticle: row.articleId,
                buttonPressed: 0
              })
            }
          />
        );
      }
      case 'salesRank': {
        return (
          <TendingCell
            rank={row.salesRank ?? 0}
            tending={row.salesRankTending ?? 0}
          />
        );
      }
      case 'productHealth': {
        return (
          <HealthCircleCell
            value={limitMinMax(unknownToNumber(row.productHealth), 0, 100)}
            size={42}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  }

  return (
    <Paper variant="outlined" className={tableClasses.paper}>
      <TableHorizontalScroll
        forwardedRef={targetOberverRef}
        leftStart={400}
        sortBy={sortBy}
        onSortChange={onSortChange}
        summary={summary}
      >
        <TableContainer className={tableClasses.tableContainer}>
          <CardTableStatus
            isLoading={state === APIStatus.PENDING}
            isError={error !== undefined}
            isNoResult={state === APIStatus.FULFILLED && rows.length === 0}
          />
          <Table stickyHeader>
            <TableHead sx={{ fontSize: '14px' }}>
              <TableRow ref={targetOberverRef}>
                {summary.map((header, i) => (
                  <SummaryHeaderCell
                    key={header.field}
                    index={i}
                    field={header.field}
                    onSortChange={onSortChange}
                    sticky={i === 0}
                    canSort={header.canSort}
                    active={sortBy.by == header.field}
                    header={{ align: header.align, label: header.label }}
                    direction={sortBy.order}
                    summary={header.summary}
                    minWidth={header.minWidth}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any, key: any) => (
                <TableRow hover key={key} className={tableClasses.tableRow}>
                  {summary.map((c, k) => (
                    <TableCell
                      className={
                        k === 0 ? `${tableClasses.cellSticky} no-padding` : ''
                      }
                      key={`${c.field}-${k}`}
                      align="center"
                      sx={{ padding: '0px' }}
                    >
                      {getCell(c.field, row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableHorizontalScroll>
    </Paper>
  );
}
