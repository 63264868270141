import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChartFiltersState } from '../../@types/filterBars';
import useOrdersTab from '../../hooks/useOrdersTab';
import AreaChartFilter from '../filters/AreaChartFilter';
import PolarAreaComponent from '../general/PolarAreaComponent';

type Props = {
  title?: string;
  articleId?: number;
};

export default function RevenueByChannel({
  title = 'revenue_by_channel',
  articleId = 0
}: Props) {
  const { t: translate } = useTranslation(['component']);

  const {
    revenueByChannelAPIStatus: state,
    revenueByChannelData: data,
    revenueByChannelFilters: filters,
    revenueByChannelError: error,
    updateRevenueByChannel
  } = useOrdersTab();

  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);

  useEffect(() => {
    updateRevenueByChannel(filterBar, articleId);
  }, [filterBar]);

  return (
    <AreaChartFilter
      showCountry={false}
      showSources={false}
      title={translate(title)}
      filters={filters}
      onFiltersChange={setFilterBar}
    >
      <PolarAreaComponent data={data} state={state} error={error} />
    </AreaChartFilter>
  );
}
