import { Icon } from '@iconify/react';
import { Divider, Theme, Typography, useTheme, Box } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { SummaryCell } from '../../general/TableRowSummary';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';

type Props = {
  cell: SummaryCell;
};

function SummaryDefaultCell({ cell }: Props) {
  const theme: Theme = useTheme();
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const euroFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const percentFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 1
  });

  const qtyFormat = new Intl.NumberFormat(currentLang.value, {
    maximumFractionDigits: 2
  });
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end'
        }}
      >
        <Typography
          variant="body1"
          textAlign={cell.align}
          fontWeight={700}
          color={theme.palette.text.primary}
          sx={{
            paddingRight: cell.paddingRight,
            paddingLeft: cell.paddingRight || '0px'
          }}
        >
          {cell.type === 'currency' && euroFormat.format(Number(cell.value))}

          {cell.type === 'percent' && percentFormat.format(Number(cell.value))}

          {cell.type === '2_decimal' && Number(cell.value).toFixed(2)}

          {cell.type === 'qty' && qtyFormat.format(Number(cell.value))}

          {cell.type === 'days' &&
            `${Math.round(Number(cell.value))} ${translate(
              'component:days_other'
            )}`}

          {cell.type === 'integer' && Math.round(Number(cell.value))}

          {cell.type === undefined && cell.value.toString()}
        </Typography>
        {cell.valuePrev !== undefined && (
          <Typography
            variant="caption"
            textAlign={cell.align}
            fontWeight={500}
            color={theme.palette.text.primary}
            sx={{
              paddingRight: cell.paddingRight,
              paddingLeft: cell.paddingRight || '0px'
            }}
          >
            {cell.type === 'currency' &&
              euroFormat.format(Number(cell.valuePrev))}

            {cell.type === 'percent' &&
              percentFormat.format(Number(cell.valuePrev))}

            {cell.type === '2_decimal' && Number(cell.valuePrev).toFixed(2)}

            {cell.type === 'qty' && qtyFormat.format(Number(cell.valuePrev))}

            {cell.type === 'days' &&
              `${Math.round(Number(cell.valuePrev))} ${translate(
                'component:days_other'
              )}`}

            {cell.type === 'integer' && Math.round(Number(cell.valuePrev))}

            {cell.type === undefined && cell.valuePrev.toString()}
          </Typography>
        )}

        {cell.value2 !== undefined && <Divider sx={{ width: '100%' }} />}
        {cell.value2 !== undefined && (
          <Typography
            variant="body1"
            textAlign={cell.align}
            fontWeight={700}
            color="text.secondary"
            sx={{ paddingRight: cell.paddingRight || '0px' }}
          >
            {cell.type === 'currency' && euroFormat.format(Number(cell.value2))}

            {cell.type === 'percent' &&
              percentFormat.format(Number(cell.value2))}

            {cell.type === '2_decimal' && Number(cell.value2).toFixed(2)}

            {cell.type === 'qty' && qtyFormat.format(Number(cell.value2))}

            {cell.type === 'integer' && Math.round(Number(cell.value2))}

            {cell.type === 'days' &&
              `${Math.round(Number(cell.value2))} ${translate(
                'component:days_other'
              )}`}

            {cell.type === undefined && cell.value2.toString()}
          </Typography>
        )}
        {cell.value2Prev !== undefined && (
          <Typography
            variant="caption"
            textAlign={cell.align}
            fontWeight={500}
            color="text.secondary"
            sx={{ paddingRight: cell.paddingRight || '0px' }}
          >
            {cell.type === 'currency' &&
              euroFormat.format(Number(cell.value2Prev))}

            {cell.type === 'percent' &&
              percentFormat.format(Number(cell.value2Prev))}

            {cell.type === '2_decimal' && Number(cell.value2Prev).toFixed(2)}

            {cell.type === 'qty' && qtyFormat.format(Number(cell.value2Prev))}

            {cell.type === 'integer' && Math.round(Number(cell.value2Prev))}

            {cell.type === 'days' &&
              `${Math.round(Number(cell.value2Prev))} ${translate(
                'component:days_other'
              )}`}

            {cell.type === undefined && cell.value2Prev.toString()}
          </Typography>
        )}
      </Box>
      {cell.calculation && (
        <Box
          sx={{
            padding: '4px',
            paddingTop: '6px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            borderRadius: '50%'
          }}
        >
          <Icon
            width={16}
            height={16}
            icon={
              cell.calculation === 'average'
                ? 'mdi:diameter-variant'
                : 'tabler:sum'
            }
            color={theme.palette.text.secondary}
          />
        </Box>
      )}
    </Box>
  );
}

export default SummaryDefaultCell;
