import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  TablePagination,
  useMediaQuery,
  CardHeader,
  Card,
  CardContent,
  Grid,
  IconButton
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import { Icon } from '@iconify/react';
import { CSVLink } from 'react-csv';
import { OrdersFilterBar } from './OrdersFilterBar';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import useOrdersTab from '../../hooks/useOrdersTab';
import { SortBy } from '../../@types/filters';
import { useCopyFilters } from '../../hooks/useCopyFilters';
import OrdersTable from './OrdersTable';
import DialogDownloadCSV from '../general/dialog/DialogDownloadCSV';
import { CSVExportData } from '../general/ExportCSV';

type OrdersProps = {
  title?: string;
  articleId?: string;
  tab?: string;
};

export default function Orders({ title, articleId, tab = '' }: OrdersProps) {
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t: translate } = useTranslation(['component']);
  const classes = TablePaginationClasses();
  const {
    getOrdersCSV,
    processCsvExportData,
    ordersFilters: filters,
    ordersTotalCount: count
  } = useOrdersTab();

  const { setUrlValues, getUrlTableValues } = useCopyFilters();
  const rowsPerPageOpts = [10, 25, 50, 100];
  const initialTableValues = useMemo(
    () =>
      getUrlTableValues({
        page: 0,
        rowsPerPage: rowsPerPageOpts[0],
        by: 'orderDate',
        order: 'desc'
      }),
    []
  );
  const [sortBy, setSortBy] = useState<SortBy>({
    by: initialTableValues.by,
    order: initialTableValues.order
  });

  const [rowsPerPage, setRowsPerPage] = useState(
    initialTableValues.rowsPerPage
  );
  const [page, setPage] = useState(initialTableValues.page);

  useEffect(() => {
    setUrlValues(filters, {
      page,
      rowsPerPage,
      by: sortBy.by,
      order: sortBy.order
    });
  }, [filters]);

  useEffect(() => {
    setUrlValues(
      filters,
      {
        page,
        rowsPerPage,
        by: sortBy.by,
        order: sortBy.order
      },
      false
    );
  }, [sortBy, page, rowsPerPage]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const csvLinkEl = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<CSVExportData>({
    fileName: '',
    data: [],
    headers: [],
    date: { startFilter: '', endFilter: '' }
  });

  useEffect(() => {
    if (csvData.data.length > 0 && csvLinkEl.current && !isLoading) {
      csvLinkEl.current.link.click();
    }
  }, [csvData]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleDownload = async (range: { from: number; to: number }) => {
    if (!isLoading) {
      setLoading(true);
      try {
        const data = await getOrdersCSV(
          filters,
          { skip: range.from, limit: range.to - range.from },
          sortBy
        );
        const dataCSV: CSVExportData = processCsvExportData(data.rows);
        setOpen(false);
        setLoading(false);
        setCsvData(dataCSV);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleCancelFetchCSV = () => {
    if (isLoading) {
      setOpen(false);
    }
    setOpen(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <OrdersFilterBar
          setInitialPage={() => setPage(0)}
          page={page}
          rowsPerPage={rowsPerPage}
          sortBy={sortBy}
          articleId={articleId}
          tab={tab}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={translate('order_listing')}
            action={
              <IconButton
                aria-label="download csv"
                onClick={() => handleOpenDialog()}
                color="primary"
              >
                <Icon width={30} icon="ph:file-csv-duotone" color="primary" />
              </IconButton>
            }
          />
          <CardContent>
            <OrdersTable
              rowsPerPage={rowsPerPage}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={rowsPerPageOpts}
                labelRowsPerPage={
                  !isMobile ? translate('global:rows_per_page') : ''
                }
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Box>
            <DialogDownloadCSV
              page={page}
              rowsPerPage={rowsPerPage}
              maxRows={count}
              onDownload={handleDownload}
              isDownloading={isLoading}
              id="csv-options-orders"
              keepMounted
              open={open}
              onClose={handleCancelFetchCSV}
            />
            <CSVLink
              separator=";"
              asyncOnClick={true}
              data={csvData.data}
              headers={csvData.headers}
              filename={csvData.fileName}
              ref={csvLinkEl}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
