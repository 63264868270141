import { useEffect, useState } from 'react';
import {
  TablePagination,
  useMediaQuery,
  Grid,
  CardContent,
  Card,
  CardHeader
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import RevenueByCountryTable from './RevenueByCountryTable';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import { APIStatus } from '../../@types/APIStatus';

import useOrdersTab from '../../hooks/useOrdersTab';
import { AreaChartFiltersState } from '../../@types/filterBars';

import SelectTimePeriod from '../filters/SelectTimePeriod';
import { FilterSelect } from '../items/filters/FilterSelect';
import useFilters from '../../hooks/useFilters';
import { ShopSelectItem, SourceSelectItem } from '../../@types/filters';

type RevProps = {
  articleId?: number;
  title?: string;
};

export default function RevenueByCountry({
  articleId = 0,
  title = 'revenue_by_country'
}: RevProps) {
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t: translate } = useTranslation(['component']);
  const {
    getSourceChannels,
    getMandantShops,
    shopList_old,
    sourcesList_old,
    filtersStatus: { sourceChannels: scStatus, mandantShops: msStatus }
  } = useFilters();

  const {
    revenueByCountryAPIStatus: state,
    revenueByCountryData: RBCData,
    revenueByCountryFilters: filters,
    revenueByCountryError: RBCError,
    APIStatus: tabState,
    updateRevenueByCountry,
    cancelRevenueByCountry,
    sortRevenueByCountry
  } = useOrdersTab();

  const classes = TablePaginationClasses();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const revenueTotal =
    (RBCData.length > 0 &&
      RBCData.map((d) => d.revenue).reduce((prev, next) => prev + next)) ||
    0;

  const [filterBar, setFilterBar] = useState<AreaChartFiltersState>(filters);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    updateRevenueByCountry(filterBar, articleId);
    return () => {
      cancelRevenueByCountry();
    };
  }, [filterBar, rowsPerPage, page, articleId]);

  useEffect(() => {
    if (msStatus === APIStatus.IDLE || msStatus === APIStatus.REJECTED) {
      getMandantShops();
    }
    if (scStatus === APIStatus.IDLE || scStatus === APIStatus.REJECTED) {
      getSourceChannels();
    }
  }, []);

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title={translate(`component:${title}`)} />
      <CardContent>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} md={4}>
            <FilterSelect
              selected={
                shopList_old.findIndex((s) => s.shopId === filterBar.shopId) + 1
              }
              title={translate('component:account')}
              labels={[
                translate('filter:all_shops'),
                ...shopList_old.map((shop: ShopSelectItem) => shop.shopName)
              ]}
              onChangeSelected={(option) => {
                setFilterBar({
                  ...filterBar,
                  shopId: option === 0 ? 0 : shopList_old[option - 1].shopId
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FilterSelect
              selected={
                sourcesList_old.findIndex(
                  (s) => s.sourceId === filterBar.sourceId
                ) + 1
              }
              title={translate('sources')}
              labels={[
                translate('filter:all_sources'),
                ...sourcesList_old.map((s: SourceSelectItem) => s.sourceName)
              ]}
              onChangeSelected={(option) => {
                setFilterBar({
                  ...filterBar,
                  sourceId:
                    option === 0 ? 0 : sourcesList_old[option - 1].sourceId
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectTimePeriod
              disabled={false}
              startFilter={filterBar.timePeriod.startFilter}
              endFilter={filterBar.timePeriod.endFilter}
              onChangePeriod={(tp) =>
                setFilterBar({
                  ...filterBar,
                  timePeriod: tp
                })
              }
            />
          </Grid>
          <Grid container spacing={2} item xs={12} justifyContent="flex-end">
            <Grid item xs={12}>
              <RevenueByCountryTable
                error={RBCError !== undefined}
                loading={
                  state === APIStatus.PENDING || tabState === APIStatus.PENDING
                }
                page={page}
                rowsPerPage={rowsPerPage}
                data={RBCData}
                revenueTotal={revenueTotal}
                onRequestSort={sortRevenueByCountry}
              />
            </Grid>
            <Grid item xs={12}>
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={
                  !isMobile ? translate('global:rows_per_page') : ''
                }
                count={RBCData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
