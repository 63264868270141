import { Box, Theme, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useLocales from '../../../hooks/useLocales';
import OneItemPercents from '../OneItemPercents';

type Props = {
  section: number;
  sources: Array<string>;
  skuList: Array<string>;
  ids: Array<number>;
  message: string;
  messageData: Array<any>;
  event: number;
  itemCount: number;
  periodDays: string;
  onGoToDetail: (buttonPressed: number, id: number, tab?: number) => void;
};

function Message({
  section,
  sources,
  message,
  messageData,
  skuList,
  ids,
  event,
  itemCount,
  periodDays,
  onGoToDetail
}: Props) {
  const { currentLang } = useLocales();
  const { t: translate } = useTranslation(['component']);
  const theme: Theme = useTheme();

  const percent = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2
  });

  const getSourceNamesList = () =>
    `${translate('component:sources')}: ${sources.map((s) => s).join(', ')}`;

  const getSourceMessage = (): string => {
    switch (true) {
      case sources.length === 1: {
        return translate(message, {
          value2:
            messageData.length > 0
              ? percent.format(Number(messageData[1] / 100))
              : '0'
        });
      }
      default: {
        return translate(message, {
          numProducts: `${itemCount}`,
          numSources: sources.length,
          productValue: messageData[0],
          lteProducts: messageData[1],
          days: periodDays,
          diff:
            messageData.length > 0
              ? percent.format(Number(messageData[1] / 100))
              : '0',
          percent:
            messageData.length > 0
              ? percent.format(Number(messageData[0] / 100))
              : '0'
        });
      }
    }
  };

  const getProductMessage = (): string => {
    switch (true) {
      case itemCount === 1: {
        return translate(message, {
          percent:
            messageData.length > 0
              ? percent.format(Number(messageData[0]) / 100)
              : '0'
        });
      }
      default: {
        if (event === 3 && messageData[1] <= 0) {
          return translate('message_out_of_stock_group', {
            numProducts: `${itemCount}`
          });
        }
        return translate(message, {
          numProducts: `${itemCount}`,
          productValue: messageData[0],
          lteProducts: messageData[1],
          months: 12,
          last_order_days: messageData[0],
          warehouses: messageData[1],
          diff:
            messageData.length > 0
              ? messageData.length > 2
                ? percent.format(Number(messageData[2]) / 100)
                : percent.format(Number(messageData[1]) / 100)
              : '0',
          percent:
            messageData.length > 0
              ? percent.format(Number(messageData[0]) / 100)
              : '0'
        });
      }
    }
  };

  function handleMouseClick(
    e: MouseEvent<HTMLButtonElement>,
    id: number,
    tab?: number
  ) {
    e.preventDefault();
    onGoToDetail(e.button, id, tab);
  }

  return (
    <Box sx={{ maxWidth: '550px' }}>
      <Box>
        {section === 1 && (
          <Typography component="p" textAlign="center" variant="body1">
            {getSourceMessage()}
          </Typography>
        )}
        {section === 1 && sources.length > 1 && sources.length < 10 && (
          <Typography
            component="p"
            textAlign="center"
            variant="body1"
            fontWeight={600}
          >
            {getSourceNamesList()}
          </Typography>
        )}

        {section === 4 && (
          <Typography component="p" textAlign="center" variant="body1">
            {getProductMessage()}
          </Typography>
        )}
        {section === 4 && skuList.length > 1 && skuList.length < 10 && (
          <Typography
            component="span"
            textAlign="center"
            variant="body1"
            fontWeight={700}
          >
            {` ${
              skuList.length === 1
                ? translate('component:product')
                : translate('menu:products')
            }:  `}
          </Typography>
        )}
        {section === 4 &&
          skuList.length > 1 &&
          skuList.length < 10 &&
          skuList.map((d, i) => (
            <Typography
              key={`${i}-${ids[i]}`}
              component="span"
              textAlign="center"
              variant="body1"
              onMouseDown={(e: MouseEvent<HTMLButtonElement>) =>
                handleMouseClick(e, ids[i])
              }
              sx={{
                marginRight: '4px',
                textDecoration: 'underline',
                '&:hover': {
                  cursor: 'pointer',
                  color: theme.palette.primary.main
                }
              }}
            >
              {(i ? ', ' : '') + d}
            </Typography>
          ))}
      </Box>
      {((section === 4 && skuList.length === 1) ||
        (section === 1 && sources.length === 1)) &&
        messageData.length > 1 && (
          <OneItemPercents event={event} values={messageData} />
        )}
    </Box>
  );
}

export default Message;
