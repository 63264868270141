import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { FiltersState } from '../../@types/reduxStates';
import {
  ArticleAttribute,
  FiltersResponse,
  OnlineMarketingRanges
} from '../../@types/responsesAPI';
import {
  ProductListRanges,
  MultiselectItem,
  MultiselectTreeItem,
  CategoryFilterItem,
  WarehouseTimePeriod
} from '../../@types/filters';

// ----------------------------------------------------------------------

const initialState: FiltersState = {
  response: {
    productListRanges: {
      maxRevenue: 100000,
      minRevenue: 0,
      maxReturns: 1000,
      minReturns: 0,
      maxMargin: 1000000,
      minMargin: 0
    },
    orders: {
      maxMargin: 0,
      maxRevenue: 0,
      minMargin: 0,
      minRevenue: 0
    },
    customers: [],
    shops: [],
    orderStatus: [],
    countries: [],
    mandantShops: [],
    sourceChannels: [],
    categories: [],
    suppliers: [],
    warehouses: [],
    stockManagementTimePeriods: [],
    manufactures: [],
    customConfigs: [],
    campaigns: [],
    campaignTypes: [],
    campaignStatus: [],
    adGroups: [],
    attributes: [],
    onlineMarketingRanges: {
      maxDailyBudget: 100,
      minDailyBudget: 0,
      minClicks: 0,
      maxClicks: 10000,
      minConversions: 0,
      maxConversions: 100,
      minImpressions: 0,
      maxImpressions: 100000
    }
  },
  filtersStatus: {
    campaigns: APIStatus.IDLE,
    campaignTypes: APIStatus.IDLE,
    campaignStatus: APIStatus.IDLE,
    adGroups: APIStatus.IDLE,
    onlineMarketingRanges: APIStatus.IDLE,
    productListRanges: APIStatus.IDLE,
    sourceChannels: APIStatus.IDLE,
    customers: APIStatus.IDLE,
    countries: APIStatus.IDLE,
    orderStatus: APIStatus.IDLE,
    categories: APIStatus.IDLE,
    suppliers: APIStatus.IDLE,
    manufactures: APIStatus.IDLE,
    warehouses: APIStatus.IDLE,
    stockManagementTimePeriods: APIStatus.IDLE,
    attributes: APIStatus.IDLE,
    mandantShops: APIStatus.IDLE
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // Article attributes
    getAttributes: (state: FiltersState) => {
      state.filtersStatus.attributes = APIStatus.PENDING;
      state.error = undefined;
    },
    getAttributesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<ArticleAttribute>>
    ) => {
      state.filtersStatus.attributes = APIStatus.FULFILLED;
      state.response.attributes = action.payload;
    },
    // Time periods (warehouses)
    getStockManagementTimePeriods: (state: FiltersState) => {
      state.filtersStatus.stockManagementTimePeriods = APIStatus.PENDING;
      state.error = undefined;
    },
    getStockManagementTimePeriodsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<WarehouseTimePeriod>>
    ) => {
      state.filtersStatus.stockManagementTimePeriods = APIStatus.FULFILLED;
      state.response.stockManagementTimePeriods = action.payload;
    },
    // Warehouses
    getWarehouses: (state: FiltersState) => {
      state.filtersStatus.warehouses = APIStatus.PENDING;
      state.error = undefined;
    },
    getWarehousesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.warehouses = APIStatus.FULFILLED;
      state.response.warehouses = action.payload;
    },
    // Manufactures
    getManufactures: (state: FiltersState) => {
      state.filtersStatus.manufactures = APIStatus.PENDING;
      state.error = undefined;
    },
    getManufacturesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.manufactures = APIStatus.FULFILLED;
      state.response.manufactures = action.payload;
    },
    // Suppliers
    getSuppliers: (state: FiltersState) => {
      state.filtersStatus.suppliers = APIStatus.PENDING;
      state.error = undefined;
    },
    getSuppliersSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.suppliers = APIStatus.FULFILLED;
      state.response.suppliers = action.payload;
    },
    // Categories
    getCategories: (state: FiltersState) => {
      state.filtersStatus.categories = APIStatus.PENDING;
      state.error = undefined;
    },
    getCategoriesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<CategoryFilterItem>>
    ) => {
      state.filtersStatus.categories = APIStatus.FULFILLED;
      state.response.categories = action.payload;
    },
    // Order status
    getOrderStatus: (state: FiltersState) => {
      state.filtersStatus.orderStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOrderStatusSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.orderStatus = APIStatus.FULFILLED;
      state.response.orderStatus = action.payload;
    },
    // Countries
    getCountries: (state: FiltersState) => {
      state.filtersStatus.countries = APIStatus.PENDING;
      state.error = undefined;
    },
    getCountriesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.countries = APIStatus.FULFILLED;
      state.response.countries = action.payload;
    },
    // Mandant/shop
    getMandantShops: (state: FiltersState) => {
      state.filtersStatus.mandantShops = APIStatus.PENDING;
      state.error = undefined;
    },
    getMandantShopsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.mandantShops = APIStatus.FULFILLED;
      state.response.mandantShops = action.payload;
    },
    // Customers
    getCustomers: (state: FiltersState) => {
      state.filtersStatus.customers = APIStatus.PENDING;
      state.error = undefined;
    },
    getCustomersSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.customers = APIStatus.FULFILLED;
      state.response.customers = action.payload;
    },
    // Sources/Channels
    getSourceChannels: (state: FiltersState) => {
      state.filtersStatus.sourceChannels = APIStatus.PENDING;
      state.error = undefined;
    },
    getSourceChannelsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.sourceChannels = APIStatus.FULFILLED;
      state.response.sourceChannels = action.payload;
    },
    // Campaigns filter
    getProductListRanges: (state: FiltersState) => {
      state.filtersStatus.productListRanges = APIStatus.PENDING;
      state.error = undefined;
    },
    getProductListRangesSuccess: (
      state: FiltersState,
      action: PayloadAction<ProductListRanges>
    ) => {
      state.filtersStatus.productListRanges = APIStatus.FULFILLED;
      state.response.productListRanges = action.payload;
    },
    // Campaigns filter
    getOnlineMarketingRanges: (state: FiltersState) => {
      state.filtersStatus.onlineMarketingRanges = APIStatus.PENDING;
      state.error = undefined;
    },
    getOnlineMarketingRangesSuccess: (
      state: FiltersState,
      action: PayloadAction<OnlineMarketingRanges>
    ) => {
      state.filtersStatus.onlineMarketingRanges = APIStatus.FULFILLED;
      state.response.onlineMarketingRanges = action.payload;
    },
    // Campaigns filter
    getCampaigns: (state: FiltersState) => {
      state.filtersStatus.campaigns = APIStatus.PENDING;
      state.error = undefined;
    },
    getCampaignsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.campaigns = APIStatus.FULFILLED;
      state.response.campaigns = action.payload;
    },
    // Campaign types filter
    getCampaignTypes: (state: FiltersState) => {
      state.filtersStatus.campaignTypes = APIStatus.PENDING;
      state.error = undefined;
    },
    getCampaignTypesSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.campaignTypes = APIStatus.FULFILLED;
      state.response.campaignTypes = action.payload;
    },
    // Campaign status filter
    getCampaignStatus: (state: FiltersState) => {
      state.filtersStatus.campaignStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getCampaignStatusSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectItem>>
    ) => {
      state.filtersStatus.campaignStatus = APIStatus.FULFILLED;
      state.response.campaignStatus = action.payload;
    },
    // Ad groups filter
    getAdGroups: (state: FiltersState) => {
      state.filtersStatus.adGroups = APIStatus.PENDING;
      state.error = undefined;
    },
    getAdGroupsSuccess: (
      state: FiltersState,
      action: PayloadAction<Array<MultiselectTreeItem>>
    ) => {
      state.filtersStatus.adGroups = APIStatus.FULFILLED;
      state.response.adGroups = action.payload;
    },
    // Other filters
    setFilters(state: FiltersState, action: PayloadAction<FiltersResponse>) {
      state.response = action.payload;
    },
    getFilters: (state: FiltersState) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getFiltersSuccess: (
      state: FiltersState,
      action: PayloadAction<FiltersResponse>
    ) => {
      state.APIStatus = APIStatus.FULFILLED;
      state.response = {
        ...state.response,
        ...action.payload
      };
    },
    getFiltersError: (state: FiltersState, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchFilterValues() {
  return async (dispatch: any) => {
    try {
      dispatch(getFilters());
      const response = await axios.get(`/api/v2/filters`);
      dispatch(getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCampaigns() {
  return async (dispatch: any) => {
    try {
      dispatch(getCampaigns());
      const response = await axios.get('/api/v2/filters/campaigns');
      dispatch(getCampaignsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCampaignTypes() {
  return async (dispatch: any) => {
    try {
      dispatch(getCampaignTypes());
      const response = await axios.get('/api/v2/filters/campaignTypes');
      dispatch(getCampaignTypesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCampaignStatus() {
  return async (dispatch: any) => {
    try {
      dispatch(getCampaignStatus());
      const response = await axios.get('/api/v2/filters/campaignStatus');
      dispatch(getCampaignStatusSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchAdGroups() {
  return async (dispatch: any) => {
    try {
      dispatch(getAdGroups());
      const response = await axios.get('/api/v2/filters/adGroups');
      dispatch(getAdGroupsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchOnlineMarketingRanges() {
  return async (dispatch: any) => {
    try {
      dispatch(getOnlineMarketingRanges());
      const response = await axios.get('/api/v2/filters/onlineMarketingRanges');
      dispatch(getOnlineMarketingRangesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchProductListRanges() {
  return async (dispatch: any) => {
    try {
      dispatch(getProductListRanges());
      const response = await axios.get('/api/v2/filters/productListRanges');
      dispatch(getProductListRangesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchSourceChannels() {
  return async (dispatch: any) => {
    try {
      dispatch(getSourceChannels());
      const response = await axios.get('/api/v2/filters/sourceChannels');
      dispatch(getSourceChannelsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCustomers() {
  return async (dispatch: any) => {
    try {
      dispatch(getCustomers());
      const response = await axios.get('/api/v2/filters/customers');
      dispatch(getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchMandantShops() {
  return async (dispatch: any) => {
    try {
      dispatch(getMandantShops());
      const response = await axios.get('/api/v2/filters/mandantShops');
      dispatch(getMandantShopsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCountries() {
  return async (dispatch: any) => {
    try {
      dispatch(getCountries());
      const response = await axios.get('/api/v2/filters/countries');
      dispatch(getCountriesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchOrderStatus(lang: string) {
  return async (dispatch: any) => {
    try {
      dispatch(getOrderStatus());
      const response = await axios.get('/api/v2/filters/orderStatus', {
        params: { lang }
      });
      dispatch(getOrderStatusSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchCategories() {
  return async (dispatch: any) => {
    try {
      dispatch(getCategories());
      const response = await axios.get('/api/v2/filters/categories');
      dispatch(getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchSuppliers() {
  return async (dispatch: any) => {
    try {
      dispatch(getSuppliers());
      const response = await axios.get('/api/v2/filters/suppliers');
      dispatch(getSuppliersSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchManufactures() {
  return async (dispatch: any) => {
    try {
      dispatch(getManufactures());
      const response = await axios.get('/api/v2/filters/manufactures');
      dispatch(getManufacturesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}
export function fetchWarehouses() {
  return async (dispatch: any) => {
    try {
      dispatch(getWarehouses());
      const response = await axios.get('/api/v2/filters/warehouses');
      dispatch(getWarehousesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchStockManagementTimePeriods() {
  return async (dispatch: any) => {
    try {
      dispatch(getStockManagementTimePeriods());
      const response = await axios.get(
        '/api/v2/filters/stockManagementTimePeriods'
      );
      dispatch(getStockManagementTimePeriodsSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

export function fetchArticleAttributes() {
  return async (dispatch: any) => {
    try {
      dispatch(getAttributes());
      const response = await axios.get('/api/v2/filters/attributes');
      dispatch(getAttributesSuccess(response.data));
    } catch (error) {
      dispatch(getFiltersError(error as APIError));
    }
  };
}

// Reducer
export default slice.reducer;

// Actions
export const {
  setFilters,
  getFilters,
  getFiltersSuccess,
  getFiltersError,
  getCampaigns,
  getCampaignsSuccess,
  getCampaignTypes,
  getCampaignTypesSuccess,
  getCampaignStatus,
  getCampaignStatusSuccess,
  getAdGroups,
  getAdGroupsSuccess,
  getOnlineMarketingRanges,
  getOnlineMarketingRangesSuccess,
  getProductListRanges,
  getProductListRangesSuccess,
  getSourceChannels,
  getSourceChannelsSuccess,
  getCustomers,
  getCustomersSuccess,
  getMandantShops,
  getMandantShopsSuccess,
  getCountries,
  getCountriesSuccess,
  getOrderStatus,
  getOrderStatusSuccess,
  getCategories,
  getCategoriesSuccess,
  getSuppliers,
  getSuppliersSuccess,
  getManufactures,
  getManufacturesSuccess,
  getWarehouses,
  getWarehousesSuccess,
  getStockManagementTimePeriods,
  getStockManagementTimePeriodsSuccess,
  getAttributes,
  getAttributesSuccess
} = slice.actions;
