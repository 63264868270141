import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { OnlineMarketingFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { OnlineMarketingState, Pagination } from '../../@types/reduxStates';
import { OnlineMarketingReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: OnlineMarketingState = {
  filters: {
    campaignStartEnabled: false,
    campaignStart: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    campaignEndEnabled: false,
    campaignEnd: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    timePeriod: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    groupBy: 0,
    columns: [],
    channel: [],
    campaign: [],
    adGroupName: [],
    campaignType: [],
    campaignStatus: [],
    dailyBudget: [0, 100],
    clicks: [0, 10000],
    impressions: [0, 100000],
    conversions: [0, 100]
  },
  response: {
    rows: [],
    total: {
      groupBy: '',
      channel: '',
      campaignName: [],
      campaignId: [],
      adGroupName: [],
      adGroupId: [],
      campaignType: [],
      dailyBudget: 0,
      startDate: [],
      endDate: [],
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      throughCpc: 0,
      cpm: 0,
      ecpm: 0,
      conversions: 0,
      conversionsValue: 0,
      costConv: 0,
      costs: 0,
      acos: 0,
      roas: 0,
      status: [],
      cvr: 0
    },
    totalCount: { count: 0 }
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'onlineMarketing',
  initialState,
  reducers: {
    setFilters: (
      state: any,
      action: PayloadAction<OnlineMarketingFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getOnlineMarketing: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOnlineMarketingSuccess: (
      state: any,
      action: PayloadAction<OnlineMarketingReponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getOnlineMarketingError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function dispatchOnlineMarketingFilters(
  filters: OnlineMarketingFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
  };
}

export async function fetchOnlineMarketingData(
  filterBar: OnlineMarketingFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  signal: AbortSignal,
  csv: boolean = false
) {
  const route = csv ? '/api/v1/onlineMarketing/csv' : '/api/v1/onlineMarketing';
  const params = {
    skip,
    limit,
    by,
    order,
    campaignStartFrom: filterBar.campaignStartEnabled
      ? filterBar.campaignStart?.startFilter
      : undefined,
    campaignStartTill: filterBar.campaignStartEnabled
      ? filterBar.campaignStart?.endFilter
      : undefined,
    campaignEndFrom: filterBar.campaignEndEnabled
      ? filterBar.campaignEnd?.startFilter
      : undefined,
    campaignEndTill: filterBar.campaignEndEnabled
      ? filterBar.campaignEnd?.endFilter
      : undefined,
    from: filterBar.timePeriod.startFilter,
    till: filterBar.timePeriod.endFilter,
    groupBy: filterBar.groupBy ? filterBar.groupBy : undefined,
    channel:
      filterBar.channel.length > 0
        ? filterBar.channel.map((c) => c.id).join(',')
        : undefined,
    campaignName:
      filterBar.campaign.length > 0
        ? filterBar.campaign.map((c) => c.id).join(',')
        : undefined,
    adGroupName:
      filterBar.adGroupName.length > 0
        ? filterBar.adGroupName.map((c) => c.id).join(',')
        : undefined,
    campaignType:
      filterBar.campaignType.length > 0
        ? filterBar.campaignType.map((c) => c.id).join(',')
        : undefined,
    campaignStatus:
      filterBar.campaignStatus.length > 0
        ? filterBar.campaignStatus.map((c) => c.id).join(',')
        : undefined,
    dailyBudgetFrom: filterBar.dailyBudget[0],
    dailyBudgetTill: filterBar.dailyBudget[1],
    impressionsFrom: filterBar.impressions[0],
    impressionsTill: filterBar.impressions[1],
    clicksFrom: filterBar.clicks[0],
    clicksTill: filterBar.clicks[1],
    conversionsFrom: filterBar.conversions[0],
    conversionsTill: filterBar.conversions[1]
  };
  const response = await axios.get(route, {
    params,
    signal
  });

  return response.data;
}

export function fetchOnlineMarketing(
  filterBar: OnlineMarketingFilterBarState,
  pagination: Pagination,
  sortBy: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(getOnlineMarketing());
    try {
      const data = await fetchOnlineMarketingData(
        filterBar,
        pagination,
        sortBy,
        abortController.signal
      );
      dispatch(getOnlineMarketingSuccess(data));
    } catch (error) {
      dispatch(getOnlineMarketingError(error as APIError));
    }
  };
}

export const {
  setFilters,
  getOnlineMarketing,
  getOnlineMarketingError,
  getOnlineMarketingSuccess
} = slice.actions;

export default slice.reducer;
