import {
  Box,
  Divider,
  makeStyles,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles<Theme, { height: number }>((theme) => ({
  sticky: {
    paddingTop: '0px',
    paddingBottom: '0px',
    borderTop: '1px solid #f1f3f4',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      boxShadow: ' -3px 0px 0px -2px #f1f3f4 inset'
    },
    position: 'sticky',
    zIndex: 5,
    left: 0,
    backgroundColor: theme.palette.grey[100],
    boxShadow: `2px 0px 4px -2px ${theme.palette.grey[500]}`,
    '&.MuiTableCell-root': {
      // borderBottom: '0px'
    }
  },
  row: {
    height: ({ height }) => height,
    backgroundColor: theme.palette.grey[100]
  },
  summary: {
    paddingTop: '0px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
    borderTop: '1px solid #f1f3f4',
    backgroundColor: theme.palette.grey[100],
    // backgroundColor: '#fff',
    '&.MuiTableCell-root MuiTableCell-head': {
      '&:last-child': {
        paddingRight: 0
      }
    },
    '&.grey': {
      backgroundColor: theme.palette.grey[100]
    }
    /* display: 'flex',
    //flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center' */
  },
  infoCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: '180px'
  }
}));

export type SummaryCell = {
  label?: string;
  value: number | string;
  value2?: number | string;
  valuePrev?: number | string;
  value2Prev?: number | string;
  type?:
    | 'days'
    | 'currency'
    | 'percent'
    | 'percent_decimal'
    | '2_decimal'
    | 'integer'
    | 'qty'
    | 'custom'
    | 'no';
  calculation?: 'average' | 'sum';
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  paddingRight?: string;
  background?: string;
  custom?: React.ReactNode;
};

type OrdersRowSummaryProps = {
  startEmptyCells: number;
  endEmptyCells: number;
  cells: Array<SummaryCell>;
  sticky?: boolean;
  height?: number;
  colSpan?: number;
};

function TableRowSummary({
  startEmptyCells,
  endEmptyCells,
  cells,
  sticky = false,
  height = 60,
  colSpan = 1
}: OrdersRowSummaryProps) {
  const { currentLang } = useLocales();
  const theme: Theme = useTheme();
  const { t: translate } = useTranslation(['component']);
  const classes = useStyles({ height });
  const { user } = useAuth();
  const iso = user.currency && user.currency.iso ? user.currency.iso : 'EUR';

  const euroFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'currency',
    currency: iso,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const percentFormat = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 1
  });

  const percentDecimal = new Intl.NumberFormat(currentLang.value, {
    style: 'percent',
    maximumFractionDigits: 2
  });

  const qtyFormat = new Intl.NumberFormat(currentLang.value, {
    maximumFractionDigits: 2
  });

  return (
    <TableRow className={classes.row}>
      <TableCell
        align="left"
        colSpan={colSpan}
        className={sticky ? classes.sticky : classes.summary}
      >
        <Box className={classes.infoCell}>
          <Typography
            variant="body1"
            textAlign="start"
            fontWeight={700}
            sx={{ paddingRight: '4px', height: '26px' }}
          >
            {translate('summary')}
          </Typography>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: '80px'
            }}
          >
            <Typography variant="body1" textAlign="start" fontWeight={700}>
              {translate('current_page')}
            </Typography>
            <Divider sx={{ width: '100%' }} />
            <Typography
              variant="body1"
              textAlign="start"
              fontWeight={700}
              color="text.secondary"
            >
              {translate('total')}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {startEmptyCells > 0 && (
        <TableCell colSpan={startEmptyCells} className={classes.summary} />
      )}

      {cells.map((cell: SummaryCell, key: number) => (
        <TableCell
          key={key}
          align={cell.align}
          className={`${classes.summary} ${cell.background ? 'grey' : ''}`}
        >
          {cell.type === 'custom' && cell.custom !== undefined && cell.custom}
          {cell.type !== 'no' && (
            <Box
              sx={{
                display: 'flex',
                // flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}
              >
                <Typography
                  variant="body1"
                  textAlign={cell.align}
                  fontWeight={700}
                  sx={{
                    paddingRight: cell.paddingRight,
                    paddingLeft: cell.paddingRight || '0px'
                  }}
                >
                  {cell.type === 'currency' &&
                    euroFormat.format(Number(cell.value))}

                  {cell.type === 'percent' &&
                    percentFormat.format(Number(cell.value))}

                  {cell.type === 'percent_decimal' &&
                    percentDecimal.format(Number(cell.value))}

                  {cell.type === '2_decimal' && Number(cell.value).toFixed(2)}

                  {cell.type === 'qty' && qtyFormat.format(Number(cell.value))}

                  {cell.type === 'days' &&
                    `${Math.round(Number(cell.value))} ${translate(
                      'component:days_other'
                    )}`}

                  {cell.type === 'integer' && Math.round(Number(cell.value))}

                  {cell.type === undefined && cell.value.toString()}
                </Typography>
                {cell.value2 !==
                  undefined /* && cell.value !== cell.value2 */ && (
                  <Divider sx={{ width: '100%' }} />
                )}
                {cell.value2 !==
                  undefined /* && cell.value !== cell.value2 */ && (
                  <Typography
                    variant="body1"
                    textAlign={cell.align}
                    fontWeight={700}
                    color="text.secondary"
                    sx={{ paddingRight: cell.paddingRight || '0px' }}
                  >
                    {cell.type === 'currency' &&
                      euroFormat.format(Number(cell.value2))}

                    {cell.type === 'percent' &&
                      percentFormat.format(Number(cell.value2))}

                    {cell.type === 'percent_decimal' &&
                      percentDecimal.format(Number(cell.value2))}

                    {cell.type === '2_decimal' &&
                      Number(cell.value2).toFixed(2)}

                    {cell.type === 'qty' &&
                      qtyFormat.format(Number(cell.value2))}

                    {cell.type === 'integer' && Math.round(Number(cell.value2))}

                    {cell.type === 'days' &&
                      `${Math.round(Number(cell.value2))} ${translate(
                        'component:days_other'
                      )}`}

                    {cell.type === undefined && cell.value2.toString()}
                  </Typography>
                )}
              </Box>
              {cell.calculation && (
                <Box
                  sx={{
                    padding: '4px',
                    paddingTop: '6px',
                    display: 'flex',
                    // flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // borderLeft: `1px solid ${theme.palette.text.secondary}`,
                    backgroundColor: '#fff',
                    borderRadius: '50%'
                  }}
                >
                  <Icon
                    width={16}
                    height={16}
                    icon={
                      cell.calculation === 'average'
                        ? 'mdi:diameter-variant'
                        : 'tabler:sum'
                    }
                    color={theme.palette.text.secondary}
                  />
                </Box>
              )}
            </Box>
          )}
        </TableCell>
      ))}

      {endEmptyCells > 0 && (
        <TableCell colSpan={endEmptyCells} className={classes.summary} />
      )}
    </TableRow>
  );
}

export default TableRowSummary;
