import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import _debounce from 'lodash/debounce';
import { TablePaginationClasses } from '../../theme/table/TableClasses';
import VariationsTable from './VariationsTable';
import { SortBy } from '../../@types/filters';
import MenuDots from '../general/MenuDots';
import useVariants from '../../hooks/useVariants';

type Props = {
  onSelectProduct: (articleId: number) => void;
  articleVariant: number;
};

export default function Variations({ onSelectProduct, articleVariant }: Props) {
  const { t: translate } = useTranslation(['component']);
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { count, getArticleVariants } = useVariants();

  const debounceFilter = useCallback(_debounce(getArticleVariants, 300), []);
  const classes = TablePaginationClasses();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sort, setSort] = useState<SortBy>({ by: 'revenue', order: 'desc' });

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    debounceFilter(
      { skip: page * rowsPerPage, limit: rowsPerPage },
      sort,
      articleVariant
    );
  }, [sort, page, rowsPerPage]);

  const handleMenuAction = (option: number) => {
    switch (option) {
      case 0: {
        window.scrollTo({ top: 351, behavior: 'smooth' });
        break;
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={translate('component:variations')}
            action={
              <MenuDots
                options={[translate('center_table')]}
                onMenuAction={handleMenuAction}
              />
            }
          />
          <CardContent>
            <VariationsTable
              page={page}
              rowsPerPage={rowsPerPage}
              sortBy={sort}
              onSortChange={setSort}
              onSelectProduct={onSelectProduct}
            />

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TablePagination
                component="div"
                className={classes.root}
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={
                  !isMobile ? translate('global:rows_per_page') : ''
                }
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                showFirstButton={true}
                showLastButton={true}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
