import { Box, Fade, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import { LightTooltip } from '../../../general/LightTooltip';
import ArticleLabel from './ArticleLabel';

const useStyles = makeStyles<Theme, { maxWidth: string }>((theme) => ({
  ellipsisContainer: {
    maxWidth: ({ maxWidth }) => maxWidth || 'auto',
    cursor: 'help'
  }
}));

type Props = {
  maxWidth?: string;
  numAttributesShow?: number;
  attributes: Array<{ attributeText: string; attributeValue: string }>;
};

const ArticleAttributes: React.FC<Props> = ({
  attributes,
  maxWidth = '124.8px',
  numAttributesShow = 3
}) => {
  const classes = useStyles({ maxWidth });
  const entriesShow = attributes.slice(0, numAttributesShow);
  return (
    <LightTooltip
      placement="top"
      title={
        <Box display="flex" flexDirection="column">
          {attributes.map(({ attributeText, attributeValue }, i) => (
            <ArticleLabel
              key={`${attributeText}-${i}`}
              label={attributeText}
              value={attributeValue}
            />
          ))}
        </Box>
      }
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignContent="flex-start"
        className={classes.ellipsisContainer}
        width="100%"
      >
        {entriesShow.map(({ attributeText, attributeValue }, i) => (
          <ArticleLabel
            key={`${i}-${attributeText}`}
            label={attributeText}
            value={attributeValue}
          />
        ))}
      </Box>
    </LightTooltip>
  );
};

export default ArticleAttributes;
