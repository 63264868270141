import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChartFiltersState, OrderFiltersState } from '../@types/filterBars';
import { DateRangeFilter, MultiselectItem, SortBy } from '../@types/filters';
import { Pagination } from '../@types/reduxStates';
import { RootState } from '../redux/rootReducer';
import {
  fetchOrderList,
  fetchOrderListData,
  fetchOrdersTab,
  fetchRevenueByChannel,
  fetchRevenueByCountry,
  resetOrdersTab,
  sortRevenueByCountryBy
} from '../redux/slices/ordersTab';
import useFilters from './useFilters';
import { OrderRow, TableHeaderCell } from '../@types/tableRows';
import useLocales from './useLocales';
import { divideIfNotZero, unknownToNumber } from '../utils/helper';
import { OrderListResponse } from '../@types/responsesAPI';
import { CSVExportData } from '../components/general/ExportCSV';

function useOrdersTab() {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation(['component']);
  const { currentLang } = useLocales();
  const {
    data: {
      revenueByChannel: {
        filters: revenueByChannelFilters,
        data: revenueByChannelData,
        APIStatus: revenueByChannelAPIStatus,
        error: revenueByChannelError
      },
      revenueByCountry: {
        filters: revenueByCountryFilters,
        data: revenueByCountryData,
        APIStatus: revenueByCountryAPIStatus,
        error: revenueByCountryError
      },
      orderList: {
        APIStatus: ordersAPIStatus,
        filters: ordersFilters,
        response: {
          rows: orderRows,
          total: ordersTotal,
          totalCount: { count: ordersTotalCount }
        },
        error: ordersError
      }
    },
    APIStatus,
    error
  } = useSelector((state: RootState) => state.ordersTab);

  const orderListabortController = new AbortController();
  const revenueByCountryAbortController = new AbortController();

  const {
    response: { sourceChannels, customers }
  } = useFilters();

  const summary: Array<TableHeaderCell> = useMemo(
    () => [
      {
        id: 1,
        field: 'orderId',
        label: translate('order_id'),
        align: 'left',
        minWidth: 220,
        canSort: true
      },
      {
        id: 2,
        field: 'channel',
        label: translate('channel'),
        align: 'center',
        minWidth: 220,
        canSort: true
      },
      {
        id: 3,
        field: 'orderDate',
        label: translate('order_date'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 4,
        field: 'orderStatusId',
        label: translate('status'),
        align: 'center',
        minWidth: 220,
        canSort: true
      },
      {
        id: 5,
        field: 'orderPaymentMethod',
        label: translate('payment'),
        align: 'center',
        minWidth: 220,
        canSort: true
      },
      {
        id: 6,
        field: 'orderCountryName',
        label: translate('ship_country'),
        align: 'center',
        minWidth: 160,
        canSort: true
      },
      {
        id: 7,
        field: 'orderItems',
        label: translate('article_qty'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value2: ordersTotal.itemsTotal,
          value: orderRows.reduce((acc, order) => acc + order.orderItems, 0),
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 8,
        field: 'orderAmountNet',
        label: translate('order_volume_net'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: orderRows.reduce(
            (acc, order) => acc + parseFloat(order.orderAmountNet),
            0
          ),
          value2: ordersTotal.amountTotalNet,
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 9,
        field: 'orderAmount',
        label: translate('order_volume_gross'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: orderRows.reduce(
            (acc, order) => acc + parseFloat(order.orderAmount),
            0
          ),
          value2: ordersTotal.amountTotal,
          type: 'currency',
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 10,
        field: 'orderMargin',
        label: translate('margin'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value:
            divideIfNotZero(
              orderRows.reduce((acc, order) => acc + order.orderMargin, 0),
              orderRows.length
            ) / 100,
          value2: ordersTotal.marginTotal / 100,
          type: 'percent',
          align: 'right',
          paddingRight: '8px',
          calculation: 'average'
        }
      }
    ],
    [orderRows, ordersTotal, currentLang]
  );

  function getOrdersUrlFilterValues(
    orderFiltersState: OrderFiltersState
  ): OrderFiltersState {
    const f: OrderFiltersState = {
      ...orderFiltersState,
      timePeriod: { ...orderFiltersState.timePeriod },
      amount: {
        ...orderFiltersState.amount,
        range: { ...orderFiltersState.amount.range },
        config: { ...orderFiltersState.amount.config }
      },
      margin: {
        ...orderFiltersState.margin,
        range: { ...orderFiltersState.margin.range },
        config: { ...orderFiltersState.margin.config }
      }
    };
    if (hash.length > 0) {
      hash
        .split('#')[1]
        .split('&')
        .forEach((part) => {
          const p = part.split('=');
          const name = p[0];
          const value = p[1];
          switch (name) {
            case 'from':
              {
                f.timePeriod = { ...f.timePeriod, startFilter: value };
              }
              break;
            case 'till':
              {
                f.timePeriod = { ...f.timePeriod, endFilter: value };
              }
              break;
            case 'status':
              {
                f.status = parseInt(value, 10);
              }
              break;
            case 'amountFrom':
              {
                f.amount.range.min = Number(value);
              }
              break;
            case 'amountTo':
              {
                f.amount.range.max = Number(value);
              }
              break;
            case 'margeFrom':
              {
                f.margin.range.min = Number(value);
              }
              break;
            case 'margeTo':
              {
                f.margin.range.max = Number(value);
              }
              break;
            case 'countryId':
              {
                f.countryId = parseInt(value, 10);
              }
              break;
            case 'returns':
              {
                f.returns = parseInt(value, 10) > 0;
              }
              break;
            case 'sourceChannels':
              {
                f.sourceChannels = value
                  .split('-')
                  .map((id) =>
                    sourceChannels.find((s) => s.id === parseInt(id, 10))
                  )
                  .filter(
                    (sourceSelectItem): sourceSelectItem is MultiselectItem =>
                      !!sourceSelectItem
                  );
              }
              break;
            case 'customersList':
              {
                f.customerList = value
                  .split('-')
                  .map((id) => customers.find((c) => c.id === parseInt(id, 10)))
                  .filter((cu): cu is MultiselectItem => !!cu);
              }
              break;
          }
        });
    }
    return f;
  }

  function getOrderStatusTranslation(
    statusName: { de?: string; en?: string },
    currentLang: string
  ): string {
    const translation = currentLang === 'de' ? statusName.de : statusName.en;
    const fallbackTranslation =
      currentLang === 'de' ? statusName.en : statusName.de;

    if (translation && translation.trim() !== '') {
      return translation;
    }

    if (fallbackTranslation && fallbackTranslation.trim() !== '') {
      return fallbackTranslation;
    }

    return '-';
  }

  const dot = new Intl.NumberFormat(currentLang.value);
  const dotDecimal = new Intl.NumberFormat(currentLang.value, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
  const dateFormatter = new Intl.DateTimeFormat(currentLang.value);

  const campaignTableFields = [
    'orderId',
    'channel',
    'orderDate',
    'orderStatusId',
    'orderPaymentMethod',
    'orderCountryName',
    'orderItems',
    'orderAmountNet',
    'orderAmount',
    'orderMargin'
  ];

  function getCsvHeaders(
    responseData: Array<OrderRow>,
    summary: Array<TableHeaderCell>
  ): Array<{ label: string; key: string }> {
    if (responseData.length === 0) {
      return [];
    }
    const firstRow = responseData[0];
    const excludedKeys = new Set([
      'orderExtId',
      'channelId',
      'paymentMethodId',
      'orderIsCancelled',
      'orderTypeId',
      'orderCountryIso',
      'orderStatusDE',
      'orderStatusEN'
    ]);
    const result: Array<{ label: string; key: string }> = [];
    for (const key of campaignTableFields) {
      if (
        !excludedKeys.has(key) &&
        Object.prototype.hasOwnProperty.call(firstRow, key)
      ) {
        const column = summary.find((col) => col.field === key);
        if (column) {
          result.push({ label: translate(column.label), key });
        }
      }
    }
    return result;
  }

  function getCsvRows(
    headers: Array<{ label: string; key: string }>,
    responseData: Array<OrderRow>
  ): Array<{ [key: string]: string | number }> {
    return responseData.map((row) => {
      const fRow: { [key: string]: string | number } = {};
      headers.forEach(({ key }) => {
        const value = row[key as keyof OrderRow];
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          switch (key) {
            case 'orderItems': {
              fRow[key] = dot.format(unknownToNumber(value));
              break;
            }
            case 'orderAmountNet':
            case 'orderAmount':
            case 'orderMargin': {
              fRow[key] = dotDecimal.format(unknownToNumber(value));
              break;
            }
            case 'orderId': {
              fRow[key] = parseInt(`${value}`, 10);
              break;
            }
            case 'orderStatusId': {
              fRow[key] = getOrderStatusTranslation(
                { de: row.orderStatusDE, en: row.orderStatusEN },
                currentLang.value
              );
              break;
            }
            case 'orderDate': {
              fRow[key] = dateFormatter.format(new Date(row.orderDate));
              break;
            }
            case 'channel':
            case 'orderPaymentMethod':
            case 'orderCountryName': {
              if (typeof value === 'string') {
                fRow[key] = `${value.replace(/"/g, '""')}`;
              } else {
                fRow[key] = `${value}`;
              }
              break;
            }
            default: {
              if (typeof value === 'string') {
                fRow[key] = `${value.replace(/"/g, '""')}`;
              } else {
                fRow[key] = `${value}`;
              }
              break;
            }
          }
        }
      });

      return fRow;
    });
  }

  const generateFilename = (dateRange: DateRangeFilter): string => {
    const translatedTitle = translate('component:order_listing');
    return `${translatedTitle}_${dateRange.startFilter}_${dateRange.endFilter}`;
  };

  const processCsvExportData = (rows: Array<OrderRow>): CSVExportData => {
    const headers = getCsvHeaders(rows, summary);
    const data = getCsvRows(headers, rows);
    const fileName = generateFilename(ordersFilters.timePeriod);
    return { headers, data, date: ordersFilters.timePeriod, fileName };
  };

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getOrdersCSV = async (
    filters: OrderFiltersState,
    pagination: Pagination,
    sortBy: SortBy,
    idArticle?: string
  ) => {
    cancelFetch();
    return fetchOrderListData(
      filters,
      pagination,
      sortBy,
      orderListabortController,
      '/api/v2/orderList/csv',
      idArticle
    );
  };

  const updateOrders = async (
    filters: OrderFiltersState,
    pagination: Pagination,
    sortBy: SortBy,
    idArticle?: string
  ) => {
    cancelFetch();
    dispatch(
      fetchOrderList(
        (filters = filters),
        pagination,
        sortBy,
        orderListabortController,
        '/api/v2/orderList',
        idArticle
      )
    );
  };

  return {
    orderRows,
    ordersTotal,
    ordersTotalCount,
    ordersAPIStatus,
    ordersFilters,
    ordersError,
    revenueByChannelFilters,
    revenueByChannelData,
    revenueByChannelAPIStatus,
    revenueByChannelError,
    revenueByCountryAPIStatus,
    revenueByCountryData,
    revenueByCountryFilters,
    revenueByCountryError,
    APIStatus,
    error,
    summary,
    getOrderStatusTranslation,
    processCsvExportData,
    getOrdersUrlFilterValues,
    resetTab: () => {
      dispatch(resetOrdersTab());
    },
    getOrdersTabData: (articleId: number, lang: string) => {
      dispatch(fetchOrdersTab(articleId, lang));
    },
    cancelRevenueByCountry: () => {
      revenueByCountryAbortController.abort();
    },
    updateRevenueByCountry: (
      filters: AreaChartFiltersState,

      idArticle?: number
    ) => {
      dispatch(
        fetchRevenueByCountry(
          filters,
          revenueByCountryAbortController,
          idArticle
        )
      );
    },
    sortRevenueByCountry: (orderBy: string, order: 'asc' | 'desc') => {
      dispatch(sortRevenueByCountryBy(revenueByCountryData, orderBy, order));
    },

    updateRevenueByChannel: (
      filters: AreaChartFiltersState,
      idArticle?: number
    ) => {
      dispatch(fetchRevenueByChannel(filters, idArticle));
    },
    cancelFetch,
    cancelOrderListFetch: () => {
      orderListabortController.abort();
    },
    getOrdersCSV,
    updateOrders
  };
}

export default useOrdersTab;
