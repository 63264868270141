import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import RowsRangeCSV from '../RowsRangeCSV';

type RadioOptionCsv = {
  label: string;
  range: { from: number; to: number };
  value: string;
};

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  page: number;
  rowsPerPage: number;
  maxRows: number;
  open: boolean;
  isDownloading: boolean;
  onClose: (value?: string) => void;
  onDownload: (range: { from: number; to: number }) => void;
}

function DialogDownloadCSV({
  page,
  rowsPerPage,
  maxRows,
  isDownloading,
  onClose,
  onDownload,
  open,
  ...other
}: ConfirmationDialogRawProps) {
  const { t: translate } = useTranslation(['component', 'filter']);
  const radioGroupRef = useRef<HTMLElement>(null);
  const [error, setError] = useState<boolean>(false);

  const options = [
    {
      label: translate('component:current_page'),
      range: {
        from: page * rowsPerPage + 1,
        to: page * rowsPerPage + rowsPerPage
      },
      value: '0'
    },
    {
      label: `${translate('filter:all')}(*)`,
      range: { from: 1, to: maxRows },
      value: '1'
    },
    {
      label: translate('component:customize'),
      range: { from: 1, to: 100 },
      value: '2'
    }
  ];

  const [optionSelected, setOptionSelected] = useState<RadioOptionCsv>(
    options[0]
  );

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    setOptionSelected(options[0]);
  }, [page, rowsPerPage, maxRows]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setOptionSelected(selectedOption);
      setError(false);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        {translate('component:download_csv')}
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="csv-options"
          name="csv-options"
          value={optionSelected.value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              key={option.value}
              control={<Radio disabled={isDownloading} />}
              label={option.label}
            />
          ))}
        </RadioGroup>
        <RowsRangeCSV
          onError={setError}
          onChangeRange={(range) =>
            setOptionSelected({ ...optionSelected, range })
          }
          range={optionSelected.range}
          disabled={isDownloading || optionSelected.value !== '2'}
        />
        <Box mt={3}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {translate('component:msg_download_csv')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={error}
          onClick={() =>
            onDownload({
              from: optionSelected.range.from - 1,
              to: optionSelected.range.to
            })
          }
        >
          {isDownloading ? (
            <CircularProgress size={20} />
          ) : (
            translate('component:download_csv')
          )}
        </Button>
        <Button onClick={handleCancel} color="primary">
          {translate('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDownloadCSV;
